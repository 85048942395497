import api from '@/http'

function getObjectValue(obj, prop) {
  return prop.split(".").reduce((o, k) => o[k], obj);
}

function isDate(date) {
  if (date instanceof Date) return true;
  else if (String(date).endsWith("Z")) return true;
  else if (String(date).match(/\d{4}-\d{2}-\d{2}/)) return true;
  return false;
}

export function getCookies() {
  const cookies = document.cookie.split(";").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    if (!value) return acc;
    acc[String(name).trim()] = value.trim();
    return acc;
  }, {});

  return cookies;
}

export default class UtilsFunc {
  static isEmptyField(message) {
    let isEmpty = true;
    return (value) => {
      if (value === null || value === undefined) isEmpty = true;
      else if (Array.isArray(value) && value.length === 0) isEmpty = true;
      else if (typeof value === "object" && Object.values(value).length === 0)
        isEmpty = true;
      else isEmpty = !value;

      if (isEmpty) return message;
      return true;
    };
  }
  static controlFilter(activeFilters) {
    return function(item) {
        if (activeFilters.is_active !== null && item.ativo !== activeFilters.is_active) {
            return false
        }
        if (activeFilters.fornecedor !== null && item.aquisicao?.fornecedor?.id !== activeFilters.fornecedor) {
            return false
        }
        if (activeFilters.nota_fiscal !== null && item.aquisicao?.id !== activeFilters.nota_fiscal) {
            return false
        }
        return true
    };
  }
  static createFilter(filters) {
    function apply(item) {
      const satisfiedFilters = filters.reduce((acc, filter) => {
        if (UtilsFunc.compareFilter(item, filter)) acc += 1;
        return acc;
      }, 0);

      return satisfiedFilters === filters.length;
    }

    return apply;
  }

  static compareFilter(item, filter) {
    const [propBeforeArr, propAfterArr] = filter.prop.split("[]");
    const itemValue = getObjectValue(item, propBeforeArr);
    // console.log(propBeforeArr)

    switch (filter.compareType) {
      case "includes":
        return String(itemValue).includes(filter.value);
      case "some":
        return itemValue.some((obj) =>
          String(getObjectValue(obj, propAfterArr))
            .toLowerCase()
            .includes(String(filter.value).toLowerCase())
        );
      case "equal":
        return itemValue === filter.value;
      case "gte":
        // console.log(
        //   filter.type === "date" || isDate(itemValue),
        //   itemValue,
        //   filter.value
        // );
        // console.log(
        //   new Date(itemValue).getTime() >= new Date(filter.value).getTime()
        // );
        return filter.type === "date" || isDate(itemValue)
          ? new Date(itemValue).getTime() >= new Date(filter.value).getTime()
          : itemValue >= filter.value;
      case "gt":
        return filter.type === "date" || isDate(itemValue)
          ? new Date(itemValue).getTime() > new Date(filter.value).getTime()
          : itemValue > filter.value;
      case "lte":
        return filter.type === "date" || isDate(itemValue)
          ? new Date(itemValue).getTime() <= new Date(filter.value).getTime()
          : itemValue <= filter.value;
      case "lt":
        return filter.type === "date" || isDate(itemValue)
          ? new Date(itemValue).getTime() < new Date(filter.value).getTime()
          : itemValue < filter.value;
    }
    return false;
  }

  static getDatesInRange(startDate, endDate) {
    const dates = [];

    let currentDate = new Date(startDate);
    endDate = new Date(endDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  static async fetchData(endpoint, loadingName, instance, dataProp) {
    try {
      instance.setLoading(loadingName, false)
      const response = await api.get(endpoint)
      instance[dataProp] = response.data
    } catch (error) {
      console.error(error)
      instance.setLoading(loadingName, true)

    } 
  }

  static setDateFormatyyyyMMdd(range = 0) {
    // cria uma nova data representando a data atual
    let currentDate = new Date();
    // subtrai 30 dias da data atual

    if (range > 0) {
      currentDate.setDate(currentDate.getDate() - range);
    }
    // obtém os valores do ano, mês e dia da data
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    let day = currentDate.getDate().toString().padStart(2, "0");

    // concatena os valores em uma string no formato "yyyy-MM-dd"
    return `${year}-${month}-${day}`;
  }

  static parseForm(event) {
    const formData = new FormData(event.target);
    const data = {};
    for (let [key, val] of formData.entries()) {
      Object.assign(data, { [key]: val || undefined });
    }

    return data;
  }
  static withCRUDUtils(props) {
    return {
      loadings: [],
      isLoading(loadingName) {
        return this.loadings.includes(loadingName);
      },
      setLoading(loadingName, remove) {
        if (remove) {
          this.loadings = this.loadings.filter((name) => name !== loadingName);
        } else if (!this.loadings.includes(loadingName)) {
          this.loadings.push(loadingName);
        }
      },
      alertWIP() {
        alert("Working in Progress");
      },
      onSelectRow(row, dialog) {
        this.selectedRow = { ...row };
        this.dialog[dialog] = true;
      },
      selectedRow: {},
      search: "",
      ...props,
      dialog: {
        create: false,
        update: false,
        remove: false,
        ...props.dialog,
      },
      tableData: [],
    };
  }

  static currencyToNumber(value) {
    const number = Number(String(value).replace(/\./g, "").replace(",", "."));
    if (!number) return null;
    return number;
  }
  static numberToCurrency(value) {
    const formater = Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format;
    if (typeof value === "number") return formater(value);
    return "";
  }
}
