import Vue from "vue";
import Vuex from "vuex";
import api from "./http";
import { router } from "./routes";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    tokenAccess: null,
    refreshToken: null,
    loading: true,
    mensagem: "",
    permissoes: {},
    modulos: [],
    pacientes: [],
    paciente: {
      id: null,
      operadora: { id: null },
      tiposanguineo: { id: null, nome: null },
    },
    userData: {},
    userDataLoading: true,
    company: {
      id: 1,
    },
    pessoa: {
      id: null,
      username: "",
      password: "",
      password2: "",
      email: "",
      conselho: { id: null },
      especialidade: { id: null },
      tipo: { id: null },
      uf_inscricao_conselho: { id: null },
      org_exped: { id: null },
    },
    alert: {
      visible: false,
      type: "info",
      message: "",
    },
    selectedEquipament: null,
    modal: false,
  },
  mutations: {
    async updateUserData(state) {
      state.userDataLoading = true;
      try {
        if (Vue.prototype.$cookies.get('accessToken') !== undefined){
          const { data } = await api.get("/contas/permissoes/");
          state.userData = data[0];
        }
      } finally {
        state.userDataLoading = false;
      }
    },
    createAlert(state, alert) {
      state.alert = alert;
      state.alert.visible = true;
    },
    closeAlert(state) {
      const type = state.alert.type;
      state.alert = {
        visible: false,
        message: "",
        type,
      };
    },
    modulos(state, val) {
      state.modulos = val;
    },
    btn_loading(state, val) {
      state.loading = val;
    },
    paciente(state, val) {
      console.log(val);
      state.paciente = val;
    },
    permissoes(state, val) {
      state.permissoes = val;
    },
    tokenAccess(state, val) {
      state.tokenAccess = val;
    },
    mensagem(state, val) {
      state.mensagem = val;
    },
    usuLogout(state) {
      state.userData = {};
      const cookies = Vue.prototype.$cookies;

      cookies.remove("accessToken");
      router.push("/login");
    },
    pacientes(state, val) {
      state.pacientes = val;
    },

    loading(state, val) {
      state.loading = val;
    },
    pessoa(state, val) {
      state.pessoa = val;
    },
    updateCompany(state, company) {
      state.company = company;

      localStorage.setItem("sadt@current-compay", JSON.stringify(company));
    },
    setSelectedEquipament(state, equipament) {
      state.selectedEquipament = equipament
    },
    setModal(state, modal) {
      state.modal = modal
    }
  },
  actions: {
    async userLogin(context, userCredentials, redirect = true) {
      const cookies = Vue.prototype.$cookies;
      const { data } = await api.post("/api/token/", userCredentials);
      cookies.set("accessToken", data.access, { expires: 0 });
      cookies.set("refreshToken", data.refresh, { expires: 0 });

      api.defaults.headers.Authorization = `Bearer ${data.access}`;
      context.commit("updateUserData");

      if (redirect) router.push("/");
    },
    updateSystemColor(_, { themes, color }) {
      themes.dark.primary = color;
      themes.light.primary = color;
      themes.dark.accent = color;
      themes.light.accent = color;
      document.documentElement.style.setProperty("--v-primary-base", color);
      localStorage.setItem("sadt@primary-color", color);
    },
    updateCompany(context, company) {
      const color = company.cor;

      localStorage.setItem("sadt@primary-color", color);
      context.commit("updateCompany", company);
    },
    btnLoading(context, value) {
      context.commit("btn_loading", value);
    },
    usuLogout: ({ commit }) => commit("usuLogout"),
    modulos(context, config) {
      return new Promise(() => {
        api(config).then((response) => {
          context.commit("modulos", response.data);
        });
      });
    },
    permissoes(context, link) {
      new Promise(() => {
        api(link)
          .then((response) => {
            context.commit("permissoes", response.data[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    crud_state(context, value) {
      console.log("crud_state", value);
      context.commit(value.state, value.data);
    },
    crud_api(context, values) {
      new Promise(() => {
        api(values.link)
          .then((response) => {
            context.commit(values.var, response.data);
          })
          .catch((error) => {
            context.commit(error);
          })
          .finally(() => {
            context.commit("loading", false);
          });
      });
    },
    loading(context, value) {
      context.commit("loading ", value);
    },
    pessoa(context, value) {
      context.commit("pessoa", value);
    },
  },
  getters: {
    userData(state) {
      return state.userData;
    },
    companyData(state) {
      const storageCompany = localStorage.getItem("sadt@current-compay");

      state.company = JSON.parse(storageCompany);
      return state.company;
    },
    isSigned(state) {
      if (state.userDataLoading) return "loading";
      return state.userData.id ? "signed" : "unsigned";
    },
    loading: (state) => {
      return state.loading;
    },
    tokenAccess: (state) => {
      return state.tokenAccess;
    },
    modulos: (state) => {
      return state.modulos;
    },
    permissoes: (state) => {
      return state.permissoes;
    },
    pacientes: (state) => {
      return state.pacientes;
    },
    paciente: (state) => {
      return state.paciente;
    },
    pessoa: (state) => {
      return state.pessoa;
    },
    selectedEquipament: (state) => {
      return state.selectedEquipament
    },
    modal: (state) => {
      return state.modal
    },
  },
});
export default store;
